import React, { useState } from 'react';
import fetch from 'isomorphic-fetch';
import classNames from 'clsx';
import { Formik, Form, Field } from 'formik';

import Loader from 'components/loader';

interface Values {
  [key: string]: string;
}

const ContactForm: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [isSuccessful, setSuccess] = useState(false);
  const [isFailing, setFail] = useState(false);
  const portalId = '7728861';
  const formId = '608ccdcf-ed32-4e6b-bf56-98fd706883f2';
  const apiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  const renderClass = (error: any, touched: any) => {
    return classNames('form__input', {
      'form__input--valid': touched,
      'form__input--invalid': error && touched,
    });
  };

  const renderError = (fieldError: any, touched: any) => {
    return fieldError && touched ? (
      <span className="form__text-error">{fieldError}</span>
    ) : null;
  };

  const validateData = (values: Values) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = 'This field is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.firstname) {
      errors.firstname = 'This field is required';
    }

    if (!values.message) {
      errors.message = 'This field is required';
    }

    return errors;
  };

  const submitData = (values: Values, setSubmitting: any) => {
    const fields = Object.keys(values).map(key => {
      return {
        name: key,
        value: values[key],
      };
    });
    const data = {
      fields: fields,
    };

    setLoading(true);

    setTimeout(() => {
      fetch(apiUrl, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => {
          setLoading(false);

          if (response.ok) {
            setSuccess(true);
          } else {
            setFail(true);
          }
        })
        .catch(() => {
          setLoading(false);
          setSuccess(false);
          setFail(true);
        });
      setSubmitting(false);
    }, 400);
  };

  return (
    <>
      <Formik
        initialValues={{
          company: 'Web Development',
          firstname: '',
          email: '',
          message: '',
        }}
        validate={values => validateData(values)}
        onSubmit={(values, props) => submitData(values, props.setSubmitting)}>
        {({ isSubmitting, touched, errors }) => {
          if (isFailing) {
            return (
              <p className="text-secondary">⚠️ Ups, something went wrong...</p>
            );
          }

          if (isSuccessful) {
            return (
              <p className="text-muted">
                🙇 Thank you for reaching out.
                <br />
                I’ll get back to you as soon as I can!
              </p>
            );
          }

          if (isLoading) {
            return <Loader />;
          }

          return (
            <Form className="form">
              <div className="form__group">
                <label className="form__label" htmlFor="field-company">
                  <span className="form__text">
                    I have an inquiry about...
                    {renderError(errors.company, touched.company)}
                  </span>
                  <Field
                    id="field-company"
                    type="text"
                    name="company"
                    className={renderClass(errors.company, touched.company)}
                  />
                  <span className="form__focus"></span>
                </label>
              </div>

              <div className="form__grid">
                <div className="form__group">
                  <label className="form__label" htmlFor="field-name">
                    <span className="form__text">
                      Your name
                      {renderError(errors.firstname, touched.firstname)}
                    </span>
                    <Field
                      id="field-name"
                      type="text"
                      name="firstname"
                      className={renderClass(
                        errors.firstname,
                        touched.firstname
                      )}
                    />
                    <span className="form__focus"></span>
                  </label>
                </div>
                <div className="form__group">
                  <label className="form__label" htmlFor="field-email">
                    <span className="form__text">
                      Email
                      {renderError(errors.email, touched.email)}
                    </span>
                    <Field
                      id="field-email"
                      type="email"
                      name="email"
                      className={renderClass(errors.email, touched.email)}
                    />
                    <span className="form__focus"></span>
                  </label>
                </div>
              </div>

              <div className="form__group">
                <label className="form__label" htmlFor="field-message">
                  <span className="form__text">
                    Please leave a message
                    {renderError(errors.message, touched.message)}
                  </span>
                  <Field
                    id="field-message"
                    as="textarea"
                    name="message"
                    cols={15}
                    rows={1}
                    className={renderClass(errors.message, touched.message)}
                  />
                  <span className="form__focus"></span>
                </label>
              </div>

              <button type="submit" className="button" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactForm;
