import React from 'react';

import Head from 'components/layout/head';
import Layout from 'components/layout';
import { Row, Column } from 'components/grid';
import Spacer from 'components/spacer/spacer';
import NewsletterForm from 'components/newsletter';
import ContactForm from 'components/contact';

const Contact: React.FC = () => (
  <Layout dark={true}>
    <Head pageTitle="Contact" />

    <Row tag="header" className="main__header">
      <Column tag="header">
        <h1 className="text-center">Get in touch</h1>
        <p className="lead text-muted text-center">
          I’m not looking for contractual work at this time
        </p>
      </Column>
    </Row>

    <Row tag="section">
      <Column className="boxed-content contact">
        <div className="contact__left">
          <h2>Contact Form</h2>
          <ContactForm />
        </div>
        <div className="contact__right">
          <h2>Newsletter</h2>
          <p className="text-light">
            Get notified when I publish new blog articles or start selling my
            soul in chunks.
          </p>
          <p>Sign up for my newsletter:</p>
          <Spacer />
          <NewsletterForm />
        </div>
      </Column>
    </Row>
  </Layout>
);

export default Contact;
